import { put, select, takeEvery } from 'typed-redux-saga';
import { uppyUploadFilesAdded, uppyUploadFilesAddedSuccess } from '../../actions';
import { uploadAssetsSelectors } from '../../../../selectors';
import { ASSET_REPO_TOKEN, IAssetRepo } from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { handleRemainingStorageFailed, handleUploadExceedsStorageLimit } from './common';

export function* uppyUploadFilesAddedEffects() {
  yield takeEvery(uppyUploadFilesAdded, handler);
}

const handler = function* (action: ReturnType<typeof uppyUploadFilesAdded>) {
  const { files, uppy } = action.payload;
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const collectionId = yield select(uploadAssetsSelectors.selectedCollectionId);
  const fileSizeSum = files.reduce((acc, file) => acc + file.size, 0);
  const remainingFileStorageResponse = yield assetRepo.listCollectionStorageRemainingBase(collectionId);
  if (remainingFileStorageResponse.data.remaining === null) {
    yield handleRemainingStorageFailed('Failed to get remaining storage.', uppy);
  } else if (remainingFileStorageResponse.data.remaining < fileSizeSum) {
    yield handleUploadExceedsStorageLimit(uppy);
  } else {
    yield put(uppyUploadFilesAddedSuccess());
  }
};
