import { Identity } from '@integration-frontends/common/auth/core/model';
import { CustomFieldKeyResponseData } from '@integration-frontends/common/brandfolder-api';
import { random } from 'lodash';
import {
  Asset,
  AssetCustomFieldValue,
  AssetTag,
  Attachment,
  Brandfolder,
  Collection,
  CustomFieldValueOption,
  Label,
  Organization,
  ResourceType,
  SearchFilter,
  Section,
  UploadPreferencesResults,
} from '..';
import { ResourceType as ApiResourceType } from '@integration-frontends/common/brandfolder-api';
import { DimensionType, ImageType } from '../../common/media';
import { ContainerCustomField, ContainerFileTypeAggregates, ContainerTag } from '../../container';
import { UppyFile } from '@brandfolder/uploader';
import { ContentPreviewType, FileVersion } from '@brandfolder/types';

export const brandfoldersMockData: Brandfolder[] = [
  {
    id: '0',
    name: 'Org1Bf1',
    type: ResourceType.BRANDFOLDER,
    organizationId: '0',
    assetCount: 1,
    cardImage: 'https://picsum.photos/25',
    hasAccess: true,
    position: 0,
  },
  {
    id: '1',
    name: 'Org1Bf2',
    type: ResourceType.BRANDFOLDER,
    organizationId: '0',
    assetCount: 5,
    cardImage: 'https://picsum.photos/65/22',
    hasAccess: true,
    position: 0,
  },
  {
    id: '2',
    name: 'Org1Bf3WithLongName',
    type: ResourceType.BRANDFOLDER,
    organizationId: '0',
    assetCount: 1000,
    cardImage: 'https://picsum.photos/60/40',
    hasAccess: true,
    position: 0,
  },
];

export const collectionsMockData: Collection[] = [
  {
    id: '3',
    name: 'Org1Col1',
    type: ResourceType.COLLECTION,
    brandfolderId: '0',
    assetCount: 5,
    cardImage: 'https://picsum.photos/25',
    hasAccess: true,
    position: 0,
  },
  {
    id: '4',
    name: 'Org1Col2',
    type: ResourceType.COLLECTION,
    brandfolderId: '0',
    assetCount: 5,
    cardImage: 'https://picsum.photos/65/22',
    hasAccess: true,
    position: 0,
  },
  {
    id: '0',
    name: 'default test collection',
    type: ResourceType.COLLECTION,
    brandfolderId: '0',
    assetCount: 0,
    cardImage: 'https://default-test-card-image',
    hasAccess: true,
    position: 0,
  },
];

export const sectionsMockData: Section[] = [
  {
    id: '0',
    name: 'section1',
    type: ResourceType.SECTION,
    brandfolderId: '0',
    assetCount: 2,
    position: 1,
    assetType: 'ExternalMedium',
  },
  {
    id: '1',
    name: 'section2',
    type: ResourceType.SECTION,
    brandfolderId: '0',
    assetCount: 999,
    position: 2,
    assetType: 'ExternalMedium',
  },
  {
    id: '2',
    name: 'Images',
    type: ResourceType.SECTION,
    brandfolderId: '0',
    assetCount: 3,
    position: 3,
    assetType: 'ExternalMedium',
  },
  {
    id: '3',
    name: 'Documents',
    type: ResourceType.SECTION,
    brandfolderId: '0',
    assetCount: 3,
    position: 4,
    assetType: 'ExternalMedium',
  },
];

export const uploadPreferencesMockData: UploadPreferencesResults = {
  organizationId: '',
  brandfolderId: '',
  collectionId: '',
  sectionId: '',
  success: true,
};

export const organizationsMockData: Organization[] = [
  {
    id: '0',
    name: 'Org1',
    type: ResourceType.ORGANIZATION,
    position: 0,
  },
  {
    id: '1',
    name: 'Org2WithLongName',
    type: ResourceType.ORGANIZATION,
    position: 0,
  },
];

export const assetsMockData: Asset[] = [
  {
    id: '0',
    type: ResourceType.GENERIC_FILE,
    name: 'Asset1',
    sectionId: '0',
    thumbnailUrl: 'https://picsum.photos/50/125',
    attachmentCount: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
    availabilityEnd: new Date(),
    position: 0,
    availability: 'published',
  },
  {
    id: '1',
    type: ResourceType.GENERIC_FILE,
    name: 'Asset2',
    sectionId: '0',
    thumbnailUrl: 'https://picsum.photos/125/125',
    attachmentCount: 1,
    position: 0,
    availability: 'published',
  },
];

export function createAssetMock(overrides: Partial<Asset> = {}): Asset {
  const id = overrides.id || crypto.randomUUID();

  return {
    extension: 'png',
    id,
    type: ResourceType.GENERIC_FILE,
    name: `Asset${id}`,
    sectionId: '0',
    thumbnailUrl: `https://picsum.photos/seed/${id}/${random(100, 400)}/${random(100, 400)}`,
    attachmentCount: 1,
    position: 0,
    ...overrides,
  };
}

export const attachmentsMockData: Attachment[] = [
  {
    id: '0',
    thumbnailUrl: 'https://picsum.photos/50/125#https://cdn.bfldr.com',
    name: 'Attachment1',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/1024/720',
    filename: 'attachment1.png',
    mimetype: 'png',
    extension: 'png',
    assetId: '0',
    dimensions: { type: DimensionType.Absolute, width: 1024, height: 720 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: true,
    updatedAt: new Date('2000-01-01T00:00:00.000Z'),
    createdAt: new Date('2000-01-01T00:00:00.000Z'),
    position: 0,
    isProcessing: false,
  },
  {
    id: '1',
    thumbnailUrl: 'https://picsum.photos/125/50',
    name: 'brandfolder-landing-banner-longs',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/500/500',
    filename: 'brandfolder-landing-banner.png',
    mimetype: 'png',
    extension: 'png',
    assetId: '0',
    dimensions: { type: DimensionType.Absolute, width: 1600, height: 300 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: true,
    updatedAt: new Date(),
    createdAt: new Date(),
    position: 0,
    isProcessing: false,
  },
  {
    id: '2',
    thumbnailUrl: 'https://picsum.photos/125/125',
    name: 'Attachment3',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/720/1024',
    filename: 'attachment3.jpg',
    mimetype: 'jpg',
    extension: 'jpg',
    assetId: '0',
    dimensions: { type: DimensionType.Absolute, width: 720, height: 1024 },
    sizeInBytes: 123456,
    mediaType: ImageType.Jpg,
    supported: true,
    updatedAt: new Date(),
    createdAt: new Date(),
    position: 0,
    isProcessing: false,
  },
  {
    id: '3',
    thumbnailUrl: 'https://picsum.photos/125/125',
    name: 'Attachment3',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/720/1024',
    filename: 'attachment3.png',
    mimetype: 'png',
    extension: 'png',
    assetId: '1',
    dimensions: { type: DimensionType.Absolute, width: 720, height: 1024 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: true,
    updatedAt: new Date(),
    createdAt: new Date(),
    position: 0,
    isProcessing: false,
  },
];

export function createAttachmentMock(overrides: Partial<Attachment> = {}): Attachment {
  const id = overrides.id || crypto.randomUUID();
  const imageUrl = `https://picsum.photos/seed/${id}/${random(100, 400)}/${random(100, 400)}`;

  return {
    id,
    thumbnailUrl: imageUrl,
    name: `Attachment${id}`,
    type: ResourceType.ATTACHMENT,
    url: imageUrl,
    filename: `Attachment${id}.${overrides.extension || 'png'}`,
    mimetype: 'png',
    extension: 'png',
    assetId: '1',
    dimensions: { type: DimensionType.Absolute, width: 720, height: 1024 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: true,
    updatedAt: new Date(),
    createdAt: new Date(),
    position: 0,
    isProcessing: false,
    ...overrides,
  };
}

export const containerTagsMockData: ContainerTag[] = [
  {
    id: '0',
    type: ResourceType.TAG,
    containerId: '0',
    name: 'brandfolder_tag1',
    searchable: true,
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.TAG,
    containerId: '0',
    name: 'brandfolder_tag2',
    searchable: true,
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.TAG,
    containerId: '0',
    name: 'brandfolder_tag3',
    searchable: true,
    position: 0,
  },
];

export function createContainerTagMock(overrides: Partial<ContainerTag> = {}): ContainerTag {
  const id = overrides.id || crypto.randomUUID();

  return {
    id,
    type: ResourceType.TAG,
    containerId: '0',
    name: `tag${id}`,
    searchable: true,
    position: 0,
    ...overrides,
  };
}

export const containerFileTypeAggregatesMockData: ContainerFileTypeAggregates[] = [
  { containerId: '0', name: 'png' },
  { containerId: '0', name: 'jpg' },
  { containerId: '0', name: 'svg' },
];

export function createContainerFileTypeAggregatesMock(
  overrides: Partial<ContainerFileTypeAggregates> = {},
): ContainerFileTypeAggregates {
  return {
    containerId: '0',
    name: 'TEST_FILE_TYPE',
    ...overrides,
  };
}

export const containerCustomFieldsMockData: ContainerCustomField[] = [
  {
    id: '0',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 1',
    prioritized: true,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 2',
    prioritized: true,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 3',
    prioritized: false,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
  {
    id: '3',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 4',
    prioritized: false,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
];

export const assetCustomFieldValuesMockData: AssetCustomFieldValue[] = [
  {
    id: '0',
    type: ResourceType.CUSTOM_FIELD_VALUE,
    assetId: '0',
    key: 'key1',
    value: 'value1',
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.CUSTOM_FIELD_VALUE,
    assetId: '0',
    key: 'key2',
    value: 'value2',
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.CUSTOM_FIELD_VALUE,
    assetId: '0',
    key: 'key3',
    value: 'value3',
    position: 0,
  },
];

export const customFieldValueOptionMockData: CustomFieldValueOption[] = [
  {
    id: '0',
    type: ResourceType.CUSTOM_FIELD_VALUE_OPTION,
    customFieldKey: '0',
    value: 'value1',
  },
  {
    id: '1',
    type: ResourceType.CUSTOM_FIELD_VALUE_OPTION,
    customFieldKey: '0',
    value: 'value2',
  },
];

export const listCustomFieldKeysResponseMockData: CustomFieldKeyResponseData = {
  data: [
    {
      id: '0',
      type: ResourceType.CUSTOM_FIELD,
      attributes: {
        name: 'name',
        allowed_values: [],
        restricted: false,
      },
    },
    {
      id: '1',
      type: 'custom_field_keys',
      attributes: {
        name: 'name',
        allowed_values: [],
        restricted: false,
      },
    },
  ],
};

export const assetTagsMockData: AssetTag[] = [
  {
    id: '0',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag1',
    autoGenerated: true,
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag2',
    autoGenerated: true,
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag3',
    autoGenerated: false,
    position: 0,
  },
  {
    id: '3',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag4',
    autoGenerated: false,
    position: 0,
  },
];

export const uploadAssetTagsMockData: {
  data: { id: string; type: ResourceType; attributes: { name: string; auto_generated: boolean } }[];
  meta?: {
    current_page: number | null;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    total_count: number;
  };
} = {
  data: [
    {
      id: '0',
      type: ResourceType.TAG,
      attributes: {
        name: 'tag1',
        auto_generated: false,
      },
    },
    {
      id: '1',
      type: ResourceType.TAG,
      attributes: {
        name: 'tag2',
        auto_generated: false,
      },
    },
    {
      id: '2',
      type: ResourceType.TAG,
      attributes: {
        name: 'tag3',
        auto_generated: false,
      },
    },
  ],
  meta: {
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_pages: 1,
    total_count: 3,
  },
};

export const searchFiltersMockData: SearchFilter[] = [
  {
    id: '1',
    type: ResourceType.SEARCH_FILTER,
    label: 'SearchFilter1',
    query: 'SearchFilter1',
    featured: true,
    containerId: '1',
    position: 1,
  },
  {
    id: '2',
    type: ResourceType.SEARCH_FILTER,
    label: 'SearchFilter2',
    query: 'SearchFilter2',
    featured: false,
    containerId: '1',
    position: 2,
  },
];

export const labelsMockData: Label[] = [
  {
    id: '1',
    type: ResourceType.LABEL,
    containerId: '0',
    path: ['1'],
    depth: 1,
    position: 1,
  },
  {
    id: '2',
    type: ResourceType.LABEL,
    containerId: '0',
    path: ['1', '2'],
    depth: 2,
    position: 1,
  },
];

export const mockIdentity: Identity = {
  token: '123',
  userId: '123',
  firstName: 'test-first-name',
  lastName: 'test-last-name',
  email: 'test-email',
};

export const longAssetsMockData: Asset[] = [];
for (let i = 0; i < 50; i++) {
  const asset: Asset = {
    id: i.toString(),
    type: ResourceType.GENERIC_FILE,
    name: `Asset${i + 1}`,
    sectionId: '0',
    thumbnailUrl: `https://picsum.photos/${50 + i}/${125}`,
    attachmentCount: i < 2 ? 3 : 1,
    createdAt: new Date(),
    updatedAt: new Date(),
    availabilityEnd: new Date(),
    position: 0,
  };
  longAssetsMockData.push(asset);
}

export const uppyFileMockData: UppyFile = {
  data: undefined,
  extension: 'png',
  id: 'uppy-screenshot/2024/04/26/at/3/24/31/pm/png-10-1d-1d-10-10-1e-1e-81f-1e-image/png-727783-1714159477382',
  isGhost: false,
  isRemote: false,
  meta: {
    filename: 'mockImage.png',
    name: 'mockImage.png',
    relativePath: null,
    type: 'image/png',
  },
  name: 'mockImage.png',
  preview: 'preview',
  progress: {
    bytesTotal: 727783,
    bytesUploaded: 727783,
    percentage: 100,
    uploadComplete: false,
    uploadStarted: 1714160703506,
  },
  size: 727783,
  source: 'react:DragDrop',
  type: 'image/png',
};

export const mockAssetUploadResponse = {
  data: [
    {
      id: '123',
      type: 'assets' as ApiResourceType,
    },
  ],
  included: [],
};

export const mockVersion: FileVersion = {
  cdn_url: 'https://cdn.brandfolder.com/abc123/at/def456/favicon.ico',
  color_space: 'RGB',
  created_at: new Date().toISOString(),
  extension: 'jpg',
  filename: 'favicon.ico',
  file_size: 300,
  height: 300,
  id: '1',
  is_processing: false,
  large_thumbnail_url:
    'https://thumbs-stage.bfldr.com/at/abc123/v/1?expiry=0&fit=bounds&height=800&sig=xyz&width=1100',
  mimetype: 'image/x-icon',
  mux_hls_url: null,
  name: 'favicon.ico',
  preview_type: ContentPreviewType.Image,
  thumbnail_url:
    'https://thumbs.brandfolder.com/at/abc123/v/1?expiry=0&fit=bounds&height=162&sig=xyz&width=262',
  size: 300,
  type: 'version',
  versioned_download_url: 'https://brandfolder.com/favicon.ico',
  view_thumbnail_retina:
    'https://thumbs-stage.bfldr.com/at/abc123/v/1?expiry=0&fit=bounds&height=800&sig=xyz&width=1100',
  width: 300
};

export const mockVersions: FileVersion[] = [mockVersion];
