import { createReducer } from '@reduxjs/toolkit';
import {
  bulkRemoveAssetsFromCollectionsInit,
  bulkRemoveAssetsFromCollectionsSuccess,
  deleteError,
  deleteAsset
} from './actions';
import { Asset } from '@integration-frontends/integration/core/model';

export interface DeleteAssetsState {
  asset: Asset | null;
  loading: boolean;
}

export const deleteAssetsInitialState: DeleteAssetsState = {
  asset: null,
  loading: false,
};

export const DELETE_ASSETS_REDUCER = createReducer(deleteAssetsInitialState, (builder) =>
  builder
    .addCase(bulkRemoveAssetsFromCollectionsInit, (state) => {
      state.loading = true;
    })
    .addCase(bulkRemoveAssetsFromCollectionsSuccess, (state) => {
      state.asset = null;
      state.loading = false;
    })
    .addCase(deleteError, (state) => {
      state.asset = null;
      state.loading = false;
    })
    .addCase(deleteAsset, (state, action) => {
      state.asset = action.payload.asset;
    })
);
