import { all, fork } from 'redux-saga/effects';
import { uploadFailureEffects } from './upload-failure';
import {
  cancelAssetUploadEffects,
  uploadEffects,
  retryAssetUploadEffects,
  uppyUploadSuccessEffects,
  clearPinnedUploadsEffects,
} from './upload';
import { updateAssetsEffects } from './update-assets';
import { uppyUploadFilesAddedEffects } from './upload/uppy-upload-files-added';
import { handleUploadErrorToastEffect } from './upload/handle-upload-error-toast';
import { createUploadErrorToastEffect } from './upload/create-upload-error-toast';
import { updateUploadErrorToastEffect } from './upload/update-upload-error-toast';
import { uploadNewVersionAddedEffects } from './upload/upload-new-version-added';
import { uploadNewVersionPostEffects } from './upload/upload-new-version-post';

export function* contentLibraryUploadAssetsEffects() {
  yield all([
    fork(uploadEffects),
    fork(uppyUploadSuccessEffects),
    fork(uppyUploadFilesAddedEffects),
    fork(clearPinnedUploadsEffects),
    fork(retryAssetUploadEffects),
    fork(updateAssetsEffects),
    fork(uploadFailureEffects),
    fork(cancelAssetUploadEffects),
    fork(handleUploadErrorToastEffect),
    fork(createUploadErrorToastEffect),
    fork(updateUploadErrorToastEffect),
    fork(uploadNewVersionAddedEffects),
    fork(uploadNewVersionPostEffects),
  ]);
}
