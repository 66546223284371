import { put, select, takeEvery } from 'typed-redux-saga';
import { uploadNewVersionAdded, uploadNewVersionLoaded, uploadNewVersionLoading } from '../../actions';
import { uploadAssetsSelectors } from '../../../../selectors';
import { ASSET_REPO_TOKEN, IAssetRepo } from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { handleRemainingStorageFailed, handleUploadExceedsStorageLimit } from './common';

export function* uploadNewVersionAddedEffects() {
  yield takeEvery(uploadNewVersionAdded, handler);
}

const handler = function* (action: ReturnType<typeof uploadNewVersionAdded>) {
  const { assetId, file, uppy } = action.payload;

  yield put(uploadNewVersionLoading({ assetId }));

  const assetRepo = DI_CONTAINER.get<IAssetRepo>(ASSET_REPO_TOKEN);
  const collectionId = yield select(uploadAssetsSelectors.selectedCollectionId);
  const remainingFileStorageResponse = yield assetRepo.listCollectionStorageRemainingBase(collectionId);

  if (remainingFileStorageResponse.data.remaining === null) {
    yield put(uploadNewVersionLoaded({ assetId }));
    yield handleRemainingStorageFailed('Failed to get remaining storage.', uppy);
  } else if (remainingFileStorageResponse.data.remaining < file.size || 0) {
    yield put(uploadNewVersionLoaded({ assetId }));
    yield handleUploadExceedsStorageLimit(uppy);
  } else {
    uppy.addFiles([file]);
  }
};
