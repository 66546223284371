import { put } from 'typed-redux-saga';
import { uppyUploadFilesAddedFailure } from '../../../actions';
import { diContainer } from '@smartsheet/cufflink';
import { LoggingStateService, loggingStateServiceKey } from '@smartsheet/logging-state-service';
import { push } from 'redux-first-history';
import { getAppRoutes } from '@integration-frontends/core/app-routes';
import { Uppy } from '@brandfolder/uploader';

export function* handleRemainingStorageFailed(error: string, uppy: Uppy) {
  const loggingStateService = diContainer.get<LoggingStateService>(loggingStateServiceKey.name);
  loggingStateService.error(
    'Error with remaining file storage on upload in file library',
    new Error(error),
    undefined,
    'content-library',
    undefined,
    'content-library',
  );
  
  yield put(uppyUploadFilesAddedFailure());
  if (uppy) uppy.cancelAll();
  
  yield put(push(`${getAppRoutes().HOME}?error=true`));
}
