import { put, takeEvery } from 'typed-redux-saga';
import { addFilesProcessing, uploadNewVersionLoaded, uploadNewVersionPost } from '../../actions';
import { ATTACHMENT_REPO_TOKEN, IAttachmentRepo } from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { AttachmentSources } from './sources';
import { handleProcessFile } from './common';
import { t } from '@lingui/core/macro';
import { createToast } from '@integration-frontends/common/notifications';

const handler = function* (action: ReturnType<typeof uploadNewVersionPost>) {
  const { assetId, attachmentId, file, uppy } = action.payload;

  // NOTE: closes dashboard if only file in uppy
  uppy.removeFile(file.id);

  const attachmentRepo = DI_CONTAINER.get<IAttachmentRepo>(ATTACHMENT_REPO_TOKEN);
  yield attachmentRepo.postAttachmentVersion(attachmentId, {
    filename: file.name || (file.data as File).name,
    mimetype: file.type || file.data.type,
    source: AttachmentSources.SmartsheetContentLibraryUppy,
    url: file.meta.brandfolderTusUrl
  });
  
  yield put(
    createToast({
      id: crypto.randomUUID(),
      toastContent: {
        contentText: t`New version uploaded. Share it with your team to start collaborating.`,
      },
      type: 'success',
    }),
  );
  yield put(uploadNewVersionLoaded({ assetId }));
  yield put(addFilesProcessing({ assetIds: [assetId] }));
  yield handleProcessFile(assetId);
};

export function* uploadNewVersionPostEffects() {
  yield takeEvery(uploadNewVersionPost, handler);
}
