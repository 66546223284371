import { FileVersion, FileVersionPost } from '@brandfolder/types';
import { isEmpty } from 'ramda';
import { BrandfolderApi } from './brandfolder-api';
import {
  AddAssetTagsResponseData,
  ApiCountDataResponse,
  ApiFetchDataResponse,
  ApiIdsDataResponse,
  ApiListDataResponse,
  ApiStorageDataResponse,
  AssetCountDto,
  AssetDto,
  AssetIdsDto,
  AssetOptions,
  AttachmentDto,
  AttachmentInputDto,
  CollectionStorageDto,
  GenericFileDto,
} from './model';
import { CLApiFiletypeAggregationsResponse, CLApiTagsAggregationsResponse } from './cl-model';

export class CLBrandfolderApi extends BrandfolderApi {
  async addAssetTags(
    apiKey: string,
    assetIds: string,
    tags: string[],
    collectionId?: string,
    locale?: string,
    source?: string,
  ): Promise<AddAssetTagsResponseData> {
    const data = {
      attributes: {
        asset_keys: assetIds,
        tag_names: tags,
        locale,
        source,
      },
    };
    return await this.post(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/tags/bulk_add`,
      { data },
    );
  }

  private async listCollection(
    apiKey: string,
    collectionId: string,
    options: AssetOptions | undefined,
    type: 'assets' | 'asset_ids',
  ): Promise<ApiListDataResponse<AssetDto> | ApiIdsDataResponse<AssetIdsDto>> {
    const isSearch = !isEmpty(options?.search_query) && Boolean(options?.search_query);

    const route = isSearch
      ? `${this.getFileLibraryApiBase()}/collections/${collectionId}/assets${
          type === 'assets' ? '/search' : '/search/asset_ids'
        }`
      : `${this.getFileLibraryApiBase()}/collections/${collectionId}/assets${
          type === 'assets' ? '' : '/asset_ids'
        }`;

    return isSearch
      ? await this.post(apiKey, route, options, {}, true)
      : await this.get(apiKey, route, options);
  }

  async listCollectionAssets(
    apiKey: string,
    collectionId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return (await this.listCollection(
      apiKey,
      collectionId,
      options,
      'assets',
    )) as ApiListDataResponse<AssetDto>;
  }

  async listCollectionAssetIds(
    apiKey: string,
    collectionId: string,
    options?: AssetOptions,
  ): Promise<ApiIdsDataResponse<AssetIdsDto>> {
    return (await this.listCollection(
      apiKey,
      collectionId,
      options,
      'asset_ids',
    )) as ApiIdsDataResponse<AssetIdsDto>;
  }

  async listCollectionAssetsCount(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiCountDataResponse<AssetCountDto>> {
    return (await this.get(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/assets/count`,
    )) as ApiCountDataResponse<AssetCountDto>;
  }

  async listCollectionStorageRemaining(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiStorageDataResponse<CollectionStorageDto>> {
    return await this.get(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/storage_remaining`,
    );
  }

  async createExternalCollectionAsset(
    apiKey: string,
    collectionId: string,
    sectionId: string,
    externalMedia: {
      url: string;
      name: string;
    },
  ): Promise<ApiFetchDataResponse<GenericFileDto>> {
    return await this.post(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/assets`,
      this.generateCreateExternalMediaCollectionAssetBody(
        sectionId,
        externalMedia.name,
        externalMedia.url,
      ),
      undefined,
    );
  }

  async createCollectionAsset(
    apiKey: string,
    collectionId: string,
    sectionId: string,
    name: string,
    attachments: AttachmentInputDto[],
  ) {
    return await this.post(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/assets`,
      this.generateCreateAssetPayload(sectionId, name, attachments[0]),
      undefined,
    );
  }

  private generateCreateAssetPayload(
    sectionId: string,
    name: string,
    attachment: AttachmentInputDto,
  ) {
    return {
      data: {
        attributes: {
          name,
          attachment,
        },
      },
      section_key: sectionId,
    };
  }

  private generateCreateExternalMediaCollectionAssetBody(
    sectionId: string,
    name: string,
    url: string,
  ) {
    return {
      data: {
        attributes: {
          data: {
            url,
          },
          description: '',
          name,
        },
      },
      section_key: sectionId,
    };
  }

  async getCollectionFiletypeAggregations(
    apiKey: string,
    collectionId: string,
  ): Promise<CLApiFiletypeAggregationsResponse> {
    const filetypesData = await this.get(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/filetype_aggregations`,
      {
        queue_priority: '',
      },
    );
    const filetypes = isEmpty(filetypesData.data) ? [] : filetypesData.data;
    return { filetypes };
  }

  async getCollectionTagAggregations(
    apiKey: string,
    collectionId: string,
  ): Promise<CLApiTagsAggregationsResponse> {
    const tagAggregationsData = await this.get(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/tag_aggregations`,
      {
        queue_priority: '',
      },
    );
    const tags = isEmpty(tagAggregationsData) ? [] : tagAggregationsData.data;
    return { tags };
  }

  async searchFilterableTags(apiKey: string, collectionId: string, search: string) {
    const filteredTagAggregationsData = await this.get(
      apiKey,
      `${this.getFileLibraryApiBase()}/collections/${collectionId}/filtered_tag_aggregations`,
      {
        queue_priority: '',
        search,
      },
    );
    return filteredTagAggregationsData.data;
  }

  async bulkDownloadAssets(apiKey: string, assetIds: string[], name?: string): Promise<any> {
    return await this.post(apiKey, `${this.getFileLibraryApiBase()}/assets/download`, {
      asset_keys: assetIds,
      name,
    });
  }

  getAssetAttachment = async (
    apiKey: string,
    assetId: string,
  ): Promise<ApiFetchDataResponse<AttachmentDto>> => {
    return await this.get(apiKey, `${this.getFileLibraryApiBase()}/assets/${assetId}/attachment`, {
      per: 0,
      queue_priority: '',
    });
  };

  getAttachmentVersions = async (apiKey: string, attachmentId: string): Promise<FileVersion[]> => {
    return await this.get(apiKey, `${this.getFileLibraryApiBase()}/attachments/${attachmentId}/versions`)
      .then((res) => res.data as FileVersion[]);
  };

  postAttachmentVersion = async (apiKey: string, attachmentId: string, attributes: FileVersionPost): Promise<FileVersion> => {
    return await this.post(apiKey, `${this.getFileLibraryApiBase()}/attachments/${attachmentId}/versions`, {
      data: {
        attributes
      }
    }).then((res) => res.data as FileVersion);
  }
}
