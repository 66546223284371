import {
  ApiSearchableAggregationsResponse,
  AssetDto,
  AttachmentDto,
  BrandfolderDto,
  CollectionDto,
  CustomFieldKeyDto,
  CustomFieldValueDto,
  CustomFieldValueResponseDto,
  getAttachmentDtoUrl,
  IncludedAttachmentDto,
  IncludedBrandfolderDto,
  LabelDto,
  OrganizationDto,
  Relationship,
  ResourceDto,
  SearchFilterDto,
  SearchQuery,
  SectionDto,
  TagDto,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Asset,
  AssetCustomFieldValue,
  AssetStatus,
  AssetTag,
  Attachment,
  AttachmentOrientation,
  AttachmentUploadDate,
  Brandfolder,
  BrandfolderCustomField,
  BrandfolderFileTypeAggregates,
  BrandfolderTag,
  Collection,
  CollectionCustomField,
  CollectionFileTypeAggregates,
  CollectionTag,
  ContainerCustomField,
  DimensionType,
  getMimeTypeMediaType,
  hasAssetStatusFilter,
  hasCustomFieldFilters,
  hasFileTypeFilters,
  hasLabelFilter,
  hasOrientationFilters,
  hasPinnedSearchFilter,
  hasQueryFilter,
  hasSkuFilters,
  hasTagFilters,
  hasUploadDateFilter,
  ImageType,
  IMediaTypeSupportService,
  isImage,
  Label,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  Organization,
  ResourceBase,
  ResourceType,
  SearchFilter,
  SearchParameters,
  SearchQueryParams,
  Section,
} from '@integration-frontends/integration/core/model';
import { addDays, format } from 'date-fns';
import { curry } from 'ramda';
import { setMediaType } from './common';
import {
  CLApiFiletypeAggregationsResponse,
  CLApiTagsAggregationsResponse,
} from 'libs/common/brandfolder-api/src/lib/cl-model';

export const GET_API_KEY_TOKEN = 'GET_API_KEY';
export type IGetAuthToken = () => Promise<string>;

export function mapAsset(assetDto: AssetDto, collectionId?: string): Asset {
  return {
    ...mapResourceFields(assetDto),
    type:
      assetDto.type === 'generic_files'
        ? ResourceType.GENERIC_FILE
        : assetDto.type === 'texts'
        ? ResourceType.TEXT
        : undefined,
    sectionId: assetDto.relationships?.[Relationship.SECTION]?.data.id,
    thumbnailUrl: assetDto.attributes.thumbnail_url,
    attachmentCount: assetDto.attributes.attachment_count,
    collectionId,
    description: assetDto.attributes.description,
    availabilityEnd: assetDto.attributes.availability_end,
    availability: assetDto.attributes.availability,
    extension: assetDto.attributes.extension !== 'empty' ? assetDto.attributes.extension : null,
  };
}

export function mapAssetTag(assetId: string, tagDto: TagDto): AssetTag {
  return {
    id: tagDto.id,
    assetId,
    type: ResourceType.TAG,
    autoGenerated: tagDto.attributes.auto_generated,
    name: tagDto.attributes.name,
    position: tagDto.attributes.position,
  };
}

export function mapAssetCustomFieldValue(
  assetId: string,
  customFieldValueDto: CustomFieldValueDto,
): AssetCustomFieldValue {
  return {
    id: customFieldValueDto.id,
    assetId,
    type: ResourceType.CUSTOM_FIELD_VALUE,
    key: customFieldValueDto.attributes.key,
    value: customFieldValueDto.attributes.value,
    position: customFieldValueDto.attributes.position,
  };
}

export type AttachmentWithDto = Attachment & { dto: AttachmentDto };
export function mapAttachmentDto(attachmentDto: AttachmentDto): AttachmentWithDto {
  const mediaTypeSupportService: IMediaTypeSupportService = DI_CONTAINER.get(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const mediaType = getMimeTypeMediaType(
    attachmentDto.attributes.mimetype,
    attachmentDto.attributes.extension,
  );
  const supported = mediaTypeSupportService.isSupported(mediaType);

  return {
    ...mapResourceFields(attachmentDto),
    type: ResourceType.ATTACHMENT,
    assetId: attachmentDto.relationships?.[Relationship.ASSET].data.id,
    url:
      supported || !isImage(attachmentDto.attributes.mimetype)
        ? getAttachmentDtoUrl(attachmentDto)?.replace('&format=png', '')
        : setMediaType(getAttachmentDtoUrl(attachmentDto), ImageType.Png),
    filename: attachmentDto.attributes.filename,
    extension: attachmentDto.attributes.extension,
    name: attachmentDto.attributes.filename,
    mimetype: attachmentDto.attributes.mimetype,
    thumbnailUrl: attachmentDto.attributes.thumbnail_url,
    dimensions: {
      width: attachmentDto.attributes.width,
      height: attachmentDto.attributes.height,
      type: DimensionType.Absolute,
    },
    sizeInBytes: attachmentDto.attributes.size,
    isProcessing: attachmentDto.attributes.is_processing,
    dto: attachmentDto,
    mediaType,
    supported,
    createdAt: new Date(attachmentDto.attributes.created_at),
    updatedAt: new Date(attachmentDto.attributes.updated_at),
  };
}

export function mapIncludedAttachmentDto(
  includedAttachmentDto: IncludedAttachmentDto,
  assetId: string,
): AttachmentWithDto {
  return mapAttachmentDto({
    ...includedAttachmentDto,
    relationships: {
      [Relationship.ASSET]: {
        data: {
          id: assetId,
        },
      },
    },
  } as AttachmentDto);
}

export function mapBrandfolder(brandfolderDto: BrandfolderDto): Brandfolder {
  if (!brandfolderDto) return null;

  return {
    ...mapResourceFields(brandfolderDto),
    type: ResourceType.BRANDFOLDER,
    assetCount: brandfolderDto.attributes.asset_count,
    organizationId: brandfolderDto.relationships?.[Relationship.ORGANIZATION]?.data.id,
    cardImage: brandfolderDto.attributes.card_image,
    name: brandfolderDto.attributes.name,
    hasAccess: true,
  };
}

export function mapIncludedBrandfolder(
  includedBrandfolderDto: IncludedBrandfolderDto,
  organizationId: string,
): Brandfolder {
  return {
    ...mapResourceFields(includedBrandfolderDto),
    type: ResourceType.BRANDFOLDER,
    id: includedBrandfolderDto.id,
    organizationId,
    name: includedBrandfolderDto.attributes.name,
    hasAccess: false,
  };
}

export function mapCollection(
  collectionDto: CollectionDto,
  brandfolderDto?: BrandfolderDto,
): Collection {
  if (!collectionDto) return null;

  return {
    ...mapResourceFields(collectionDto),
    name: `${collectionDto.attributes.name}`,
    type: ResourceType.COLLECTION,
    assetCount: collectionDto.attributes.asset_count || 0,
    brandfolderId: collectionDto.relationships?.[Relationship.BRANDFOLDER]?.data.id || '',
    hasAccess: true,
    slug: `${brandfolderDto?.attributes.slug || ''}/${collectionDto.attributes.slug}`,
  };
}

export function mapCollectionWorkspace(collectionDto: CollectionDto): Collection {
  if (!collectionDto) return null;

  return {
    ...mapResourceFields(collectionDto),
    name: `${collectionDto.attributes.name}`,
    type: ResourceType.COLLECTION,
    assetCount: 0,
    brandfolderId: '',
    hasAccess: true,
    slug: collectionDto.attributes.slug,
  };
}

export function mapOrganization(organizationDto: OrganizationDto): Organization {
  return {
    id: organizationDto.id,
    type: ResourceType.ORGANIZATION,
    name: organizationDto.attributes.name,
    position: organizationDto.attributes.position,
  };
}

export const mapSection = (sectionDto: SectionDto): Section => {
  return {
    id: sectionDto.id,
    type: ResourceType.SECTION,
    name: sectionDto.attributes.name,
    position: sectionDto.attributes.position,
    brandfolderId: sectionDto.relationships?.[Relationship.BRANDFOLDER]?.data.id,
    assetType: sectionDto.attributes.default_asset_type,
  };
};

export function mapBrandfolderCustomField(
  brandfolderId: string,
  customFieldKeyDto: CustomFieldKeyDto,
  searchableAggregations: ApiSearchableAggregationsResponse,
): BrandfolderCustomField {
  return {
    ...mapCustomField(customFieldKeyDto, searchableAggregations),
    brandfolderId,
  };
}

export function mapBrandfolderCustomFieldValues(
  brandfolderId: string,
  customFieldValues: string[],
): { customFieldValues: string[]; brandfolderId: string } {
  return {
    customFieldValues,
    brandfolderId,
  };
}

export function mapCollectionCustomField(
  collectionId: string,
  customFieldKeyDto: CustomFieldKeyDto,
  searchableAggregations: ApiSearchableAggregationsResponse,
): CollectionCustomField {
  return {
    ...mapCustomField(customFieldKeyDto, searchableAggregations),
    collectionId,
  };
}

function mapCustomField(
  customFieldKeyDto: CustomFieldKeyDto,
  searchableAggregations: ApiSearchableAggregationsResponse,
): Omit<ContainerCustomField, 'containerId'> {
  return {
    ...mapResourceFields(customFieldKeyDto),
    type: ResourceType.CUSTOM_FIELD,
    searchable: searchableAggregations.custom_fields
      .flatMap(Object.values)
      .includes(customFieldKeyDto.id),
    prioritized: customFieldKeyDto.attributes.prioritized,
    allowedValues: customFieldKeyDto.attributes.allowed_values,
  };
}

export function mapCustomFieldValueList(
  customFieldValueResponseDto: string[],
  customFieldKey: string,
): any[] {
  return customFieldValueResponseDto.map((value, i) => ({
    customFieldKey,
    id: `${customFieldKey}${i}`,
    type: ResourceType.CUSTOM_FIELD,
    value: value,
  }));
}

export function mapBrandfolderTag(
  brandfolderId: string,
  searchableAggregationsTag: ApiSearchableAggregationsResponse['tags'][0],
): BrandfolderTag {
  return {
    id: `${brandfolderId}-${searchableAggregationsTag}`,
    brandfolderId,
    type: ResourceType.TAG,
    searchable: true,
    position: 0,
    name: searchableAggregationsTag,
  };
}

export function mapCollectionTag(
  collectionId: string,
  searchableAggregationsTag: ApiSearchableAggregationsResponse['tags'][0],
): CollectionTag {
  return {
    id: `${collectionId}-${searchableAggregationsTag}`,
    collectionId,
    type: ResourceType.TAG,
    searchable: true,
    position: 0,
    name: searchableAggregationsTag,
  };
}

export function mapBrandfolderFileTypeAggregates(
  brandfolderId: string,
  searchableAggregations: ApiSearchableAggregationsResponse,
): BrandfolderFileTypeAggregates[] {
  return searchableAggregations.filetypes.map((extension) => ({
    name: extension,
    brandfolderId,
  }));
}

export function mapCollectionFileTypeAggregates(
  collectionId: string,
  searchableAggregations: ApiSearchableAggregationsResponse,
): CollectionFileTypeAggregates[] {
  return (
    searchableAggregations.filetypes?.map((extension) => ({
      name: extension,
      collectionId,
    })) || []
  );
}

export function mapCollectionFiletypeAggregations(
  collectionId: string,
  filetypeAggregations: CLApiFiletypeAggregationsResponse,
): CollectionFileTypeAggregates[] {
  return filetypeAggregations.filetypes.map((extension) => ({
    name: extension,
    collectionId,
  }));
}

export function mapCollectionTagAggregations(
  collectionId: string,
  tagAggregations: CLApiTagsAggregationsResponse,
): CollectionTag[] {
  return tagAggregations.tags.map((tag) => ({
    id: `${collectionId}-${tag}`,
    collectionId,
    type: ResourceType.TAG,
    searchable: true,
    position: 0,
    name: tag,
  }));
}

export const mapSearchFilter = curry(
  (containerId: string, searchFilter: SearchFilterDto): SearchFilter => {
    return {
      ...mapResourceFields(searchFilter),
      type: ResourceType.SEARCH_FILTER,
      label: searchFilter.attributes.label,
      query: searchFilter.attributes.query,
      featured: searchFilter.attributes.featured,
      containerId,
    };
  },
);

export function mapLabel(containerId: string, labelDto: LabelDto): Label {
  return {
    ...mapResourceFields(labelDto),
    type: ResourceType.LABEL,
    containerId,
    path: labelDto.attributes.path,
    depth: labelDto.attributes.depth,
  };
}

function mapResourceFields(
  resourceDto: ResourceDto,
): Pick<ResourceBase, 'id' | 'name' | 'slug' | 'createdAt' | 'updatedAt' | 'position'> {
  const { name, slug, created_at, updated_at, position } = resourceDto.attributes;

  return {
    id: resourceDto.id,
    name,
    slug,
    createdAt: created_at && new Date(created_at),
    updatedAt: updated_at && new Date(updated_at),
    position,
  };
}

export function buildSearchQuery(searchParams: SearchParameters): string {
  if (!searchParams) return null;

  const searchFragments = [];

  if (hasQueryFilter(searchParams)) {
    searchFragments.push(`(${searchParams.query})`);
  }

  if (hasTagFilters(searchParams)) {
    const tags = searchParams.tagFilters.tags.map((tag) => tag.name);
    searchFragments.push(`tags:(${tags.join(` ${searchParams.tagFilters.operator || ' OR '} `)})`);
  }

  if (hasCustomFieldFilters(searchParams)) {
    const customFieldFilters = Object.values(searchParams.customFieldFilters).filter(Boolean);
    searchFragments.push(
      customFieldFilters
        .map((filter) => `custom_fields.${filter.customField.name}:${filter.value}`)
        .join(' AND '),
    );
  }

  if (hasFileTypeFilters(searchParams)) {
    searchFragments.push(`filetype:(${searchParams.fileTypeFilters.fileTypes.join(' OR ')})`);
  }

  if (hasAssetStatusFilter(searchParams)) {
    searchFragments.push(`${mapAssetStatus(searchParams.assetStatusFilter)}:true`);
  }

  if (hasOrientationFilters(searchParams)) {
    searchFragments.push(
      `aspect:(${searchParams.orientationFilters
        .map((oFilter) => `${mapOrientation(oFilter)}`)
        .join(' OR ')})`,
    );
  }

  if (hasSkuFilters(searchParams)) {
    searchFragments.push(`(${searchParams.skuFilters.join(' OR ')})`);
  }

  if (hasUploadDateFilter(searchParams)) {
    const DATE_FORMAT = 'yyyy-MM-dd';

    switch (searchParams.uploadDateFilter.uploadDateEnum) {
      case AttachmentUploadDate.All:
        searchFragments.filter((fragment) => !fragment.includes('created_at'));
        break;
      case AttachmentUploadDate.DateRange:
        searchFragments.push(
          `created_at:[${format(searchParams.uploadDateFilter.dateStart, DATE_FORMAT)} TO ${format(
            addDays(searchParams.uploadDateFilter.dateEnd, 1), // Brandfolder API does not include the end date
            DATE_FORMAT,
          )}]`,
        );
        break;
      case AttachmentUploadDate.Last30Minutes:
        searchFragments.push(`created_at:>now-30m`);
        break;
      case AttachmentUploadDate.Past24Hours:
        searchFragments.push(`created_at:>now-1d`);
        break;
      case AttachmentUploadDate.Past7Days:
        searchFragments.push(`created_at:>now-7d`);
        break;
    }
  }

  if (hasLabelFilter(searchParams)) {
    searchFragments.push(`labels:"${searchParams.labelFilter.name}"`);
  }

  if (hasPinnedSearchFilter(searchParams)) {
    searchFragments.push(`(${searchParams.pinnedSearch.query})`);
  }

  return searchFragments.join(' AND ');
}

export function buildSearchQueryBase(searchParams: SearchQueryParams): SearchQuery {
  if (!searchParams) return null;

  const searchQuery: any = {};

  if (hasQueryFilter(searchParams)) {
    searchQuery.query = searchParams.query;
  }

  if (hasOrientationFilters(searchParams)) {
    searchQuery.aspect = {
      operator: 'OR',
      filters: searchParams.orientationFilters.map((oFilter) => mapOrientation(oFilter)),
    };
  }

  if (hasFileTypeFilters(searchParams)) {
    searchQuery.extensions = {
      operator: 'OR',
      filters: searchParams.fileTypeFilters.fileTypes,
    };
  }

  if (hasTagFilters(searchParams)) {
    searchQuery.tag_names = {
      operator: searchParams.tagFilters.operator || 'OR',
      filters: searchParams.tagFilters.tags.map((tag) => tag.name),
    };
  }

  if (
    hasUploadDateFilter(searchParams) &&
    searchParams.uploadDateFilter.uploadDateEnum !== AttachmentUploadDate.All
  ) {
    const DATE_FORMAT = 'yyyy-MM-dd';
    searchQuery.created_at = { filters: [] };

    switch (searchParams.uploadDateFilter.uploadDateEnum) {
      case AttachmentUploadDate.DateRange:
        searchQuery.created_at.filters.push(
          `[${format(searchParams.uploadDateFilter.dateStart, DATE_FORMAT)} TO ${format(
            addDays(searchParams.uploadDateFilter.dateEnd, 1),
            DATE_FORMAT,
          )}]`,
        );
        break;
      case AttachmentUploadDate.Last30Minutes:
        searchQuery.created_at.filters.push('>now-30m');
        break;
      case AttachmentUploadDate.Past24Hours:
        searchQuery.created_at.filters.push('>now-1d');
        break;
      case AttachmentUploadDate.Past7Days:
        searchQuery.created_at.filters.push('>now-7d');
        break;
    }
  }

  return searchQuery;
}

function mapAssetStatus(status: AssetStatus): string {
  switch (status) {
    case AssetStatus.Approved:
      return 'approved';
    case AssetStatus.Expired:
      return 'expired';
    case AssetStatus.Draft:
      return 'draft';
    case AssetStatus.Unapproved:
      return 'unapproved';
  }
}

function mapOrientation(orientation: AttachmentOrientation): string {
  switch (orientation) {
    case AttachmentOrientation.Horizontal:
      return 'landscape';
    case AttachmentOrientation.Panoramic:
      return 'panorama';
    case AttachmentOrientation.Square:
      return 'square';
    case AttachmentOrientation.Vertical:
      return 'portrait';
  }
}
