import { createReducer } from '@reduxjs/toolkit';
import {
  deselectAllAssets,
  selectAllAssetsError,
  selectAllAssetsInit,
  selectAllAssetsSuccess,
  shiftSelectAssetsSuccess,
  toggleAssetSelection,
} from './actions';

export const DEFAULT_SELECTED_ASSETS_LIMIT = 1000;

export interface SelectAssetState {
  selectedAssetIds: string[];
  loading: boolean;
}

export const selectAssetInitialState: SelectAssetState = {
  selectedAssetIds: [],
  loading: false,
};

export const SELECT_ASSET_REDUCER = createReducer(selectAssetInitialState, (builder) => {
  builder
    .addCase(selectAllAssetsInit, (state) => {
      state.loading = true;
    })
    .addCase(selectAllAssetsError, (state) => {
      state.loading = false;
    })
    .addCase(selectAllAssetsSuccess, (state, action) => {
      state.loading = false;
      state.selectedAssetIds = action.payload.assetIds.slice(0, DEFAULT_SELECTED_ASSETS_LIMIT);
    })
    .addCase(deselectAllAssets, (state) => {
      state.selectedAssetIds = [];
    })
    .addCase(toggleAssetSelection, (state, action) => {
      const index = state.selectedAssetIds.findIndex(
        (assetId) => assetId === action.payload.assetId,
      );

      if (index !== -1) {
        state.selectedAssetIds = state.selectedAssetIds.filter(
          (assetId) => assetId !== action.payload.assetId,
        );
      } else {
        if (state.selectedAssetIds.length < DEFAULT_SELECTED_ASSETS_LIMIT) {
          state.selectedAssetIds = [...state.selectedAssetIds, action.payload.assetId];
        }
      }
    })
    .addCase(shiftSelectAssetsSuccess, (state, action) => {
      const { assetIds } = action.payload;
      state.selectedAssetIds = assetIds;
    });
});
